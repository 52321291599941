import React, { useState } from 'react';
import { Flex, Box, Button, Heading, Text } from 'rebass';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import CouponBox from './CouponBox'
import Header1 from '../../components/Commons/Headers/Header1'

import strings from './strings.json';
// import Divider from '../../components/Divider';
// import formatPrice from '../../utils/formatPrice';
// import useShopifyFunctions from '../../hooks/useShopifyFunctions';
import LineItem from '../../components/Cart/LineItem';
import { StateConsumer } from '../../context/StateContext'
import { roundUp } from '../../helpers/ContentHelpers'

// const { cartSubtotalLabel, cartCheckoutButton, cartHeader } = strings;

const CheckoutButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.fonts.sans,
}));

const CheckoutButton1 = props => {
  return (
    <a onClick={props.onClick} className={'checkout-btn'}>{props.children}</a>
  )
}

function CartPage() {
  // const data = useStaticQuery(graphql`
  //   {
  //     site {
  //       siteMetadata {
  //         gatsbyStorefrontConfig {
  //           locales
  //           currency
  //         }
  //       }
  //     }
  //   }
  // `);
  // const { locales, currency } = data.site.siteMetadata.gatsbyStorefrontConfig;
  //
  // const { checkout } = useShopifyFunctions();
  // const { subtotalPrice } = checkout;

  // const displaySubtotalPrice = formatPrice(
  //   Number(subtotalPrice),
  //   locales,
  //   currency
  // );

  // const buttonEnabled = checkout.loaded && checkout.lineItems.length > 0;

  return (
    <React.Fragment>

    <StateConsumer>

    {({updateModal, modalType, modal, cart, addToCart, updateCartItemQuantity, pushCartToCheckout, appliedDiscount}) => {

      async function decreaseProductAmount({ id, quantity }) {
        if (quantity === 1) return;
        let newQuantity = quantity - 1;
        try {
          await updateCartItemQuantity(id, newQuantity);
        } catch (error) {
          console.error(error);
        }
      }

      async function increaseProductAmount({ id, quantity }) {
        let newQuantity = quantity + 1;
        try {
          await updateCartItemQuantity(id, newQuantity);
        } catch (error) {
          console.error(error);
        }
      }

      async function removeItemFromCart({ id }) {
        let newQuantity = parseInt(0);
        console.log(newQuantity);
        try {
          await updateCartItemQuantity(id, newQuantity);
        } catch (error) {
          console.error(error);

        }
      }

      let cartItems = cart.items,
          cartSubtotal = cart.subtotal,
          cartTotal = cartSubtotal,
          cartDiscount

      if(appliedDiscount.valid === 1){

        let { type, value } = appliedDiscount


        value = value.replace('-','')

        if(type === 'percentage') {
          cartTotal = cartTotal - ((value / 100) * cartSubtotal)
          cartDiscount = `( ${value}% off )`
        }
        if(type === 'fixed_amount') {
          cartTotal = cartTotal - value
          cartDiscount = roundUp(value, 2)
          cartDiscount = `( -$${cartDiscount} off )`
        }

        cartTotal = roundUp(cartTotal, 2)

      }

        return (
  <>

    <Header1 title={'Your Shopping Cart'} />


<div className={'cart-section section pt-0 pt-lg-0 pt-md-70 pt-sm-60 pt-xs-50 pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50'}>
<div class="container">
<div class="row mb--96 mb-md--57">
<div class="col-12">


<div class="row">
<div class="col-lg-8">
<div class="table-content table-responsive cartTable">



<Flex>
  <Box width={1}>
  <Flex
    flexDirection={['column', 'row']}
    alignItems="top"
    className="cartTableHead"
    >


      <Flex
      width={[1, 1 / 20]}
      display={['none', 'block']}
      justifyContent={['center', 'flex-start']}
      >

      </Flex>
      <Flex
      width={[1, 2 / 10, 1 / 4.5]}
      p={[1, 3]}
      justifyContent={['center', 'flex-start']}
      >

      </Flex>
      <Flex
      width={[1, 4 / 10, 5 / 10]}
      p={[1, 3]}
      justifyContent={['center', 'flex-start']}
      >
        Product
      </Flex>
      <Flex width={[1, 1 / 10]}
      p={[1, 3]}
      justifyContent={['center', 'flex-start']}>
        Price
      </Flex>
      <Flex width={[1, 2 / 10]}
      p={[1, 3]}
      justifyContent={['center', 'flex-start']}>
        Quantity
      </Flex>
      <Flex width={[1, 1 / 10]}
      p={[1, 3]}
      justifyContent={['center', 'flex-start']}>
        Total
      </Flex>

    </Flex>

    <Flex>
      <Box mt={2} width={1}>
        {(cartItems && cartItems.length > 0) &&
          cartItems.map((cartItem, key) => (
            <React.Fragment>
              <LineItem
                key={key}
                cartItem={cartItem}
                decreaseProductAmount={decreaseProductAmount}
                increaseProductAmount={increaseProductAmount}
                removeItemFromCart={removeItemFromCart}
                mb={[4, 15]}
              />
              {/*<Divider bg="grey" my={1} display={['block', 'none']} />*/}
            </React.Fragment>
          ))}
      </Box>
    </Flex>


<CouponBox />


  </Box>
</Flex>


</div>

</div>



<div class="col-lg-4">
                                    <div class="cart-totals">
                                        <div class="cart-calculator ccnCard">
                                            <h2>Cart totals</h2>
                                            <div class="cart-calculator__item">
                                                <div class="cart-calculator__item--head">
                                                    <span>Subtotal</span>
                                                </div>
                                                <div class="cart-calculator__item--value">
                                                    <span>{cartSubtotal}</span>
                                                </div>
                                            </div>
                                            {appliedDiscount.valid === 1 &&
                                              <div class="cart-calculator__item">
                                                  <div class="cart-calculator__item--head">
                                                      <span>Discount</span>
                                                  </div>
                                                  <div class="cart-calculator__item--value">
                                                      <span>{appliedDiscount.code}</span>
                                                  </div>
                                                  <div class="cart-calculator__item--head">
                                                      <span></span>
                                                  </div>
                                                  {cartDiscount &&
                                                    <div class="cart-calculator__item--value">
                                                      <span>{cartDiscount}</span>
                                                    </div>
                                                  }
                                              </div>
                                            }
                                            <div class="cart-calculator__item order-total">
                                                <div class="cart-calculator__item--head">
                                                    <span>Total</span>
                                                </div>
                                                <div class="cart-calculator__item--value">
                                                    <span>{cartTotal}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <CheckoutButton1 onClick={pushCartToCheckout}>
                                          Checkout
                                        </CheckoutButton1>
                                    </div>
                                </div>



</div>

</div>
</div>
</div>
</div>




</>

)}}

</StateConsumer>





    </React.Fragment>
  );
}

export default CartPage;
