import React from 'react';
import GatsbyLink from 'gatsby-link';
import Breadcrumbs from '../Breadcrumbs'

function Header1(props) {

  return (
    <>
      <div class="breadcrumb-section section bg-image">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="breadcrumb-title text-center">
                            <h1>{props.title}</h1>
                            <Breadcrumbs title={props.title} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Header1;
