import React, { useState, useEffect, useContext } from 'react';
import { Flex, Box, Image, Text } from 'rebass';
import StateContext from '../../context/StateContext'
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components"
import RemoveItemIcon from './RemoveItemIcon';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const CouponValue = styled.div`
  display: inline-flex;
  align-items: center;
  color: #5c5c5c;
  padding: 0 10px;
  margin: 0 10px;
  svg {
    padding-right: 4px;
  }
  b {
    padding-left: 10px;
  }
`

const CouponRemove = styled.div`
  display: inline-flex;
  align-items: center;
  color: #888;
  font-size: 12px;
  &:hover {
    color: rgb(252, 79, 79);
  }
  transition: all .2s ease;
  cursor: pointer;
`

const Input = React.forwardRef(({ label, name, placeholder, register }, ref) => (
  <input className="form__input" placeholder={placeholder} name={name} ref={ref} />
));

const Submit = React.forwardRef(({ label, name, register }, ref) => (
  <button type="submit" name={name} ref={ref} class="coupon_btn">{label}</button>
));

const CouponBox = props => {

  const {
    appliedDiscount,
    applyCouponCode
  } = useContext(StateContext)

  const { register, handleSubmit, watch, errors } = useForm()

  const onSubmit = async data => {
    let code = data.coupon_code
    await applyCouponCode(code).then(async resp => {
      await console.log(resp)
      // console.log("data")
    })
  }

  // console.log(couponCode)

  return (
    <div class="row pt-30">
      <div class="col-lg-6 col-sm-7">
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div class="coupon-form-areas">
            <Input placeholder="Coupon code" name="coupon_code" ref={register} />
            <Submit label="Apply Coupon" name="submit_button" />
          </div>
        </form>
      </div>
      <div class="col-lg-6 col-sm-5 text-sm-right">
        {appliedDiscount.valid === 1 &&
          <Flex justifyContent="flex-end" fontSize={14}>
            <CouponValue>
              <CheckCircleOutlineOutlinedIcon /> Redeemed: <b>{appliedDiscount.code}</b>
            </CouponValue>
          {/*  <CouponRemove onClick={(v) => { applyCouponCode(null)}}>
              (Remove)
            </CouponRemove> */}
          </Flex>
        }
        {/*<button type="submit" class="cart-btn" onClick={() => removeAllFromCart(cartItems)}>Clear Cart</button>
        <button type="submit" class="cart-btn">Update Cart</button>*/}
      </div>
    </div>
  )

}

export default CouponBox;
